import faker from 'faker';
import { Operator } from '../@types/operators';
import mock from './mock';

export const operators: Operator[] = Array.from({ length: 10 }, () => ({
  id: faker.datatype.uuid(),
  fullName: faker.name.findName(),
  photoUrl: ''
}));

mock.onGet('/api/operators').reply(200, operators);
