import copyFill from '@iconify/icons-eva/copy-fill';
import { Icon } from '@iconify/react';
import { Alert, Box, Card, Container, IconButton, Stack, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
  const { translate } = useLocales();

  return (
    <RootStyle title="Login">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {translate('login.welcome')}
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {translate('login.signInTo')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{translate('login.details')}</Typography>
            </Box>
          </Stack>

          <Alert severity="info" sx={{ mb: 3 }}>
            email:{' '}
            <CopyToClipboard text="admin@livesale.cz">
              <span>
                <strong>admin@livesale.cz</strong>
                <IconButton
                  sx={{
                    padding: 0.3
                  }}
                >
                  <Icon icon={copyFill} width={14} height={14} />
                </IconButton>
              </span>
            </CopyToClipboard>{' '}
            or{' '}
            <CopyToClipboard text="op@livesale.cz">
              <span>
                <strong>op@livesale.cz</strong>
                <IconButton
                  sx={{
                    padding: 0.3
                  }}
                >
                  <Icon icon={copyFill} width={14} height={14} />
                </IconButton>
              </span>
            </CopyToClipboard>
            <br />
            password:{' '}
            <CopyToClipboard text="demo1234">
              <span>
                <strong>demo1234</strong>
                <IconButton
                  sx={{
                    padding: 0.3
                  }}
                >
                  <Icon icon={copyFill} width={14} height={14} />
                </IconButton>
              </span>
            </CopyToClipboard>
            <strong>&nbsp;</strong>
          </Alert>

          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
