import { createSlice } from '@reduxjs/toolkit';
import { CallOrder, CallOrdersState, Filter } from '../../@types/call-orders';
import axios from '../../utils/axios';
import { store } from '../store';

const initialState: CallOrdersState = {
  loading: true,
  error: false,
  filter: {
    priority: '',
    status: 'new',
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toString(),
    dateTo: new Date().toString(),
    text: '',
    tags: []
  },
  callOrders: [],
  callOrder: null,
  isOpenModal: false
};

const slice = createSlice({
  name: 'callOrders',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    getCallOrdersSuccess(state, action) {
      state.loading = false;
      state.callOrders = action.payload;
    },
    error(state) {
      state.loading = false;
      state.error = true;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    openModal(state) {
      state.isOpenModal = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
    },
    getCallOrderSuccess(state, action) {
      state.loading = false;
      state.callOrder = action.payload;
    },
    unsetCallOrders(state) {
      state.callOrders = [];
    },
    unsetCallOrder(state) {
      state.callOrder = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, setFilter, unsetCallOrders, unsetCallOrder } = slice.actions;

export function getCallOrders(filter: Filter) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: CallOrder[];
      } = await axios.get('/api/call-orders', { params: filter });

      dispatch(slice.actions.getCallOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}

export function getCallOrder(callOrderId: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: CallOrder;
      } = await axios.get('/api/call-order', { params: { callOrderId } }); // should be ideally /api/call-orders/<id>

      dispatch(slice.actions.getCallOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}
