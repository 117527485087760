import useAuth from '../hooks/useAuth';
import createAvatar from '../utils/createAvatar';
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user!.photoUrl}
      alt={user!.displayName}
      color={user!.photoUrl ? 'default' : createAvatar(user!.displayName).color}
      {...other}
    >
      {createAvatar(user!.displayName).name}
    </MAvatar>
  );
}
