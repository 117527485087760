import axios from 'axios';
import { parseISO } from 'date-fns';
import { apiConfig } from '../config';
import { isIsoDateString } from './dateHelpers';

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = parseISO(value);
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  }

  return body;
}

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl
});

axiosInstance.interceptors.response.use(
  (response) => {
    handleDates(response.data);
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
