import { Box, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import * as React from 'react';
import { Status } from '../../@types/account';
import { MButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { getStatusColor } from '../../utils/getColorName';

const STATUSES = [
  { id: 'online', name: 'Online' },
  { id: 'busy', name: 'Busy' },
  { id: 'waiting', name: 'Waiting' },
  { id: 'offline', name: 'Offline' }
];

const StatusBox = ({ statusId }: { statusId: string }) => (
  <Box
    sx={{
      display: 'inline-block',
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor: getStatusColor(statusId),
      mr: 1
    }}
  />
);

export const StatusPopover = () => {
  const { user, changeStatus } = useAuth();
  const { translate } = useLocales();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const onChangeStatus = (newStatus: Status) => {
    changeStatus({ status: newStatus });
  };

  if (user!.role.id !== 'operator') {
    return null;
  }

  return (
    <>
      <MButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          backgroundColor: alpha(getStatusColor(user!.status.id), 0.12),
          ml: { xs: 1.5, sm: 2, lg: 3 }
        }}
      >
        <StatusBox statusId={user!.status.id} />
        <Typography variant="button" color={getStatusColor(user!.status.id)}>
          {translate(`account.status.${user!.status.id}`)}
        </Typography>
      </MButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {STATUSES.map((status) => (
            <MenuItem
              key={status.id}
              selected={status.id === user!.status.id}
              onClick={() => {
                onChangeStatus(status);
                setOpen(false);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                <StatusBox statusId={status.id} />
                {translate(`account.status.${status.id}`)}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};
