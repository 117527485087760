import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_analytics'),
  callOrders: getIcon('ic_ecommerce'),
  callsHistory: getIcon('ic_elements'),
  chat: getIcon('ic_chat'),
  widget: getIcon('ic_components'),
  employeeManagement: getIcon('ic_user'),
  settings: getIcon('ic_authenticator')
};

const sidebarConfig = [
  {
    title: 'layout.dashboard',
    path: PATH_DASHBOARD.dashboard,
    icon: ICONS.dashboard,
    roles: ['operator', 'manager', 'admin', 'super-admin']
  },
  {
    title: 'layout.callOrders',
    path: PATH_DASHBOARD.callOrders.root,
    icon: ICONS.callOrders,
    roles: ['operator', 'manager', 'admin', 'super-admin']
  },
  {
    title: 'layout.callsHistory',
    path: PATH_DASHBOARD.callsHistory.root,
    icon: ICONS.callsHistory,
    roles: ['operator', 'manager', 'admin', 'super-admin']
  },
  {
    title: 'layout.chat.root',
    path: PATH_DASHBOARD.chat.root,
    icon: ICONS.chat,
    roles: ['operator', 'manager', 'admin', 'super-admin'],
    children: [
      { title: 'layout.chat.waitingCustomers', path: PATH_DASHBOARD.chat.active },
      { title: 'layout.chat.history', path: PATH_DASHBOARD.chat.history }
    ]
  },
  {
    title: 'layout.widget',
    path: PATH_DASHBOARD.widget,
    icon: ICONS.widget,
    roles: ['admin', 'super-admin']
  },
  {
    title: 'layout.employeeManagement',
    path: PATH_DASHBOARD.employeeManagement.root,
    icon: ICONS.employeeManagement,
    roles: ['manager', 'admin', 'super-admin']
  },
  {
    title: 'layout.settings',
    path: PATH_DASHBOARD.settings,
    icon: ICONS.settings,
    roles: ['admin', 'super-admin']
  }
];

export default sidebarConfig;
