import { createSlice } from '@reduxjs/toolkit';
import {
  AssignedCallOrders,
  CallOrders,
  CallOrderStatistics,
  CallsAverageRating,
  CallsSuccessRate,
  CurrentChats,
  DashboardState,
  LatestCallOrders,
  PastCalls,
  TotalPastCalls,
  WaitingChats,
  WidgetConversion
} from '../../@types/dashboard';
import axios from '../../utils/axios';
import { store } from '../store';

const initialState: DashboardState = {
  operator: {
    totalPastCalls: {
      loading: true,
      error: false,
      totalCalls: 0,
      percent: 0
    },
    callsSuccessRate: { loading: true, error: false, successRate: 0, percent: 0 },
    callsAverageRating: { loading: true, error: false, rating: 0 },
    pastCalls: {
      loading: true,
      error: false,
      data: [],
      categories: []
    },
    waitingChats: {
      loading: true,
      error: false,
      total: 0,
      messages: []
    },
    callOrders: {
      loading: true,
      error: false,
      totalNew: 0,
      totalMy: 0,
      new: [],
      my: []
    }
  },
  manager: {
    latestCallOrders: {
      loading: true,
      error: false,
      callOrders: []
    },
    assignedCallOrders: {
      loading: true,
      error: false,
      stats: []
    },
    currentChats: {
      loading: true,
      error: false,
      waiting: {
        total: 0,
        messages: []
      },
      processing: {
        total: 0,
        messages: []
      }
    },
    callOrderStatistics: {
      loading: true,
      error: false,
      data: [],
      categories: []
    }
  },
  admin: {
    widgetConversion: {
      loading: true,
      error: false,
      data: [],
      categories: []
    }
  }
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startLoadingCallOrders(state) {
      state.operator.callOrders.loading = true;
      state.operator.callOrders.error = false;
    },
    errorCallOrders(state) {
      state.operator.callOrders.loading = false;
      state.operator.callOrders.error = true;
    },
    getCallOrdersSuccess(state, action) {
      state.operator.callOrders = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingPastCalls(state) {
      state.operator.pastCalls.loading = true;
      state.operator.pastCalls.error = false;
    },
    errorPastCalls(state) {
      state.operator.pastCalls.loading = false;
      state.operator.pastCalls.error = true;
    },
    getPastCallsSuccess(state, action) {
      state.operator.pastCalls = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingTotalPastCalls(state) {
      state.operator.totalPastCalls.loading = true;
      state.operator.totalPastCalls.error = false;
    },
    errorTotalPastCalls(state) {
      state.operator.totalPastCalls.loading = false;
      state.operator.totalPastCalls.error = true;
    },
    getTotalPastCallsSuccess(state, action) {
      state.operator.totalPastCalls = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingCallsSuccessRate(state) {
      state.operator.callsSuccessRate.loading = true;
      state.operator.callsSuccessRate.error = false;
    },
    errorCallsSuccessRate(state) {
      state.operator.callsSuccessRate.loading = false;
      state.operator.callsSuccessRate.error = true;
    },
    getCallsSuccessRateSuccess(state, action) {
      state.operator.callsSuccessRate = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingCallsAverageRating(state) {
      state.operator.callsAverageRating.loading = true;
      state.operator.callsAverageRating.error = false;
    },
    errorCallsAverageRating(state) {
      state.operator.callsAverageRating.loading = false;
      state.operator.callsAverageRating.error = true;
    },
    getCallsAverageRatingSuccess(state, action) {
      state.operator.callsAverageRating = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingWaitingChats(state) {
      state.operator.waitingChats.loading = true;
      state.operator.waitingChats.error = false;
    },
    errorWaitingChats(state) {
      state.operator.waitingChats.loading = false;
      state.operator.waitingChats.error = true;
    },
    getWaitingChatsSuccess(state, action) {
      state.operator.waitingChats = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingLatestCallOrders(state) {
      state.manager.latestCallOrders.loading = true;
      state.manager.latestCallOrders.error = false;
    },
    errorLatestCallOrders(state) {
      state.manager.latestCallOrders.loading = false;
      state.manager.latestCallOrders.error = true;
    },
    getLatestCallOrdersSuccess(state, action) {
      state.manager.latestCallOrders = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingAssignedCallOrders(state) {
      state.manager.assignedCallOrders.loading = true;
      state.manager.assignedCallOrders.error = false;
    },
    errorAssignedCallOrders(state) {
      state.manager.assignedCallOrders.loading = true;
      state.manager.assignedCallOrders.error = false;
    },
    getAssignedCallOrdersSuccess(state, action) {
      state.manager.assignedCallOrders = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingCurrentChats(state) {
      state.manager.currentChats.loading = true;
      state.manager.currentChats.error = false;
    },
    errorCurrentChats(state) {
      state.manager.currentChats.loading = true;
      state.manager.currentChats.error = false;
    },
    getCurrentChatsSuccess(state, action) {
      state.manager.currentChats = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingCallOrderStatistics(state) {
      state.manager.callOrderStatistics.loading = true;
      state.manager.callOrderStatistics.error = false;
    },
    errorCallOrderStatistics(state) {
      state.manager.callOrderStatistics.loading = true;
      state.manager.callOrderStatistics.error = false;
    },
    getCallOrderStatisticsSuccess(state, action) {
      state.manager.callOrderStatistics = {
        error: false,
        loading: false,
        ...action.payload
      };
    },
    startLoadingWidgetConversion(state) {
      state.admin.widgetConversion.loading = true;
      state.admin.widgetConversion.error = false;
    },
    errorWidgetConversion(state) {
      state.admin.widgetConversion.loading = true;
      state.admin.widgetConversion.error = false;
    },
    getWidgetConversionSuccess(state, action) {
      state.admin.widgetConversion = {
        error: false,
        loading: false,
        ...action.payload
      };
    }
  }
});

// Reducer
export default slice.reducer;

export function getCallOrders() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingCallOrders());

    try {
      const response: { data: CallOrders } = await axios.get('/api/dashboard/call-orders');

      dispatch(slice.actions.getCallOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorCallOrders());
    }
  };
}

export function getPastCalls(type: 'week' | 'month') {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingPastCalls());

    try {
      const response: {
        data: PastCalls;
      } = await axios.get('/api/dashboard/past-calls', { params: { type } });

      dispatch(slice.actions.getPastCallsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorPastCalls());
    }
  };
}

export function getTotalPastCalls() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingTotalPastCalls());

    try {
      const response: {
        data: TotalPastCalls;
      } = await axios.get('/api/dashboard/total-past-calls');

      dispatch(slice.actions.getTotalPastCallsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorTotalPastCalls());
    }
  };
}

export function getCallsSuccessRate() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingCallsSuccessRate());

    try {
      const response: {
        data: CallsSuccessRate;
      } = await axios.get('/api/dashboard/calls-success-rate');

      dispatch(slice.actions.getCallsSuccessRateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorCallsSuccessRate());
    }
  };
}

export function getCallsAverageRating() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingCallsAverageRating());

    try {
      const response: {
        data: CallsAverageRating;
      } = await axios.get('/api/dashboard/calls-rating');

      dispatch(slice.actions.getCallsAverageRatingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorCallsAverageRating());
    }
  };
}

export function getWaitingChats() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingWaitingChats());

    try {
      const response: {
        data: WaitingChats;
      } = await axios.get('/api/dashboard/waiting-chats');

      dispatch(slice.actions.getWaitingChatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorWaitingChats());
    }
  };
}

export function getLatestCallOrders() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingLatestCallOrders());

    try {
      const response: {
        data: LatestCallOrders;
      } = await axios.get('/api/dashboard/latest-call-orders');

      dispatch(slice.actions.getLatestCallOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorLatestCallOrders());
    }
  };
}

export function getAssignedCallOrders() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingAssignedCallOrders());

    try {
      const response: {
        data: AssignedCallOrders;
      } = await axios.get('/api/dashboard/assigned-call-orders');

      dispatch(slice.actions.getAssignedCallOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorAssignedCallOrders());
    }
  };
}

export function getCurrentChats() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingCurrentChats());

    try {
      const response: {
        data: CurrentChats;
      } = await axios.get('/api/dashboard/current-chats');

      dispatch(slice.actions.getCurrentChatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorCurrentChats());
    }
  };
}

export function getCallOrderStatistics(type: 'week' | 'month') {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingCallOrderStatistics());

    try {
      const response: {
        data: CallOrderStatistics;
      } = await axios.get('/api/dashboard/call-order-statistics', { params: { type } });

      dispatch(slice.actions.getCallOrderStatisticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorCallOrderStatistics());
    }
  };
}

export function getWidgetConversion(type: 'week' | 'month') {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoadingWidgetConversion());

    try {
      const response: {
        data: WidgetConversion;
      } = await axios.get('/api/dashboard/widget-conversion', { params: { type } });

      dispatch(slice.actions.getWidgetConversionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.errorWidgetConversion());
    }
  };
}
