import faker from 'faker/locale/cz';
import { sample, sampleSize, uniqBy } from 'lodash';
import { CallOrder, Priority } from '../@types/call-orders';
import { AssignedCallOrder } from '../@types/dashboard';
import fakeRequest from '../utils/fakeRequest';
import { capString } from '../utils/formatString';
import mock from './mock';
import { operators } from './operators';

const callOrders = {
  totalNew: 90,
  totalMy: 4,
  new: Array.from({ length: 10 }, () => ({
    id: faker.datatype.uuid(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber('+420 ### ### ###'),
    note: faker.lorem.sentences()
  })),
  my: Array.from({ length: 4 }, () => ({
    id: faker.datatype.uuid(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber('+420 ### ### ###'),
    note: faker.lorem.sentences()
  }))
};

mock.onGet('/api/dashboard/call-orders').reply(async () => {
  try {
    await fakeRequest(1000);

    return [200, { ...callOrders }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

const getDates = (range: number) =>
  Array.from({ length: range }, (_, i) => {
    const d = new Date();
    return new Date(d.setDate(d.getDate() - i));
  }).sort((a, b) => a.getTime() - b.getTime());

const pastCalls: Record<string, {}> = {
  week: {
    data: [
      {
        name: 'dashboard.pastCalls.labels.coBrowsing',
        data: [40, 45, 2, 8, 69, 23, 12]
      },
      {
        name: 'dashboard.pastCalls.labels.phone',
        data: [0, 0, 1, 1, 10, 18, 6]
      }
    ],
    categories: getDates(7)
  },
  month: {
    data: [
      {
        name: 'dashboard.pastCalls.labels.coBrowsing',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.pastCalls.labels.phone',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      }
    ],
    categories: getDates(30)
  }
};

mock.onGet('/api/dashboard/past-calls').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { type } = config.params;

    return [200, { ...pastCalls[type as 'week' | 'month'] }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/total-past-calls').reply(async () => {
  try {
    await fakeRequest(1000);

    return [200, { totalCalls: 69420, percent: 0.042 }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/calls-success-rate').reply(async () => {
  try {
    await fakeRequest(1000);

    return [200, { successRate: 0.975, percent: -0.001 }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/calls-rating').reply(async () => {
  try {
    await fakeRequest(1000);

    return [200, { rating: 4.2 }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/waiting-chats').reply(async () => {
  try {
    await fakeRequest(1000);

    return [
      200,
      {
        total: 3,
        messages: Array.from({ length: 3 }, () => ({
          id: faker.datatype.uuid(),
          text: capString(faker.lorem.sentence(20), 75),
          totalUnreadMessages: faker.datatype.number(5),
          lastMessageDate: faker.date.between(faker.date.recent(), new Date())
        })).sort((a, b) => a.lastMessageDate.getTime() - b.lastMessageDate.getTime())
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/latest-call-orders').reply(async () => {
  try {
    await fakeRequest(1000);

    const newCallOrders = Array.from<unknown, CallOrder>({ length: 15 }, () => ({
      id: faker.datatype.uuid(),
      dateCreated: faker.date.past().toString(),
      priority: Priority.Normal,
      email: faker.internet.email(),
      phone: faker.phone.phoneNumber('+420 ### ### ###'),
      note: faker.lorem.sentences(),
      status: 'new',
      tags: sampleSize(
        ['notebooky', 'dell', 'pocitace', 'it', 'mda'],
        faker.datatype.number({ min: 1, max: 5 })
      )
    }));

    return [200, { callOrders: newCallOrders }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/assigned-call-orders').reply(async () => {
  try {
    await fakeRequest(1000);

    const stats = uniqBy(
      Array.from<unknown, AssignedCallOrder>({ length: 15 }, () => ({
        operator: sample(operators)!,
        count: faker.datatype.number({ min: 0, max: 10 }),
        oldestCallOrder: faker.date.past().toString()
      })),
      (callOrder) => callOrder.operator.id
    ).sort((a, b) => b.count - a.count);

    return [200, { stats }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/dashboard/current-chats').reply(async () => {
  try {
    await fakeRequest(1000);

    return [
      200,
      {
        waiting: {
          total: faker.datatype.number(10),
          messages: Array.from({ length: 3 }, () => ({
            id: faker.datatype.uuid(),
            text: capString(faker.lorem.sentence(20), 75),
            totalUnreadMessages: faker.datatype.number({ min: 1, max: 5 }),
            lastMessageDate: faker.date.between(faker.date.recent(), new Date())
          })).sort((a, b) => a.lastMessageDate.getTime() - b.lastMessageDate.getTime())
        },
        processing: {
          total: faker.datatype.number(10),
          messages: Array.from({ length: 3 }, () => ({
            id: faker.datatype.uuid(),
            text: capString(faker.lorem.sentence(20), 75),
            totalUnreadMessages: faker.datatype.number({ min: 1, max: 5 }),
            lastMessageDate: faker.date.between(faker.date.recent(), new Date()),
            assignedOperator: sample(operators)
          })).sort((a, b) => a.lastMessageDate.getTime() - b.lastMessageDate.getTime())
        }
      }
    ];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

const callOrderStatistics: Record<string, {}> = {
  week: {
    data: [
      {
        name: 'dashboard.callOrderStatistics.labels.new',
        data: [40, 45, 43, 42, 42, 38, 50]
      },
      {
        name: 'dashboard.callOrderStatistics.labels.assigned',
        data: [40, 45, 35, 38, 42, 20, 18]
      },
      {
        name: 'dashboard.callOrderStatistics.labels.closed',
        data: [0, 0, 20, 25, 32, 18, 6]
      }
    ],
    categories: getDates(7)
  },
  month: {
    data: [
      {
        name: 'dashboard.callOrderStatistics.labels.new',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.callOrderStatistics.labels.assigned',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.callOrderStatistics.labels.closed',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      }
    ],
    categories: getDates(30)
  }
};

mock.onGet('/api/dashboard/call-order-statistics').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { type } = config.params;

    return [200, { ...callOrderStatistics[type as 'week' | 'month'] }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

const widgetConversion: Record<string, {}> = {
  week: {
    data: [
      {
        name: 'dashboard.widgetConversion.labels.opened',
        data: Array.from({ length: 7 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.operatorsOpened',
        data: Array.from({ length: 7 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.chatInitiated',
        data: Array.from({ length: 7 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.hyperlinksClicked',
        data: Array.from({ length: 7 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.promoImageClicked',
        data: Array.from({ length: 7 }, () => faker.datatype.number(100))
      }
    ],
    categories: getDates(7)
  },
  month: {
    data: [
      {
        name: 'dashboard.widgetConversion.labels.opened',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.operatorsOpened',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.chatInitiated',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.hyperlinksClicked',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      },
      {
        name: 'dashboard.widgetConversion.labels.promoImageClicked',
        data: Array.from({ length: 30 }, () => faker.datatype.number(100))
      }
    ],
    categories: getDates(30)
  }
};

mock.onGet('/api/dashboard/widget-conversion').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { type } = config.params;

    return [200, { ...widgetConversion[type as 'week' | 'month'] }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});
