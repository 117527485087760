import { Operator } from './operators';

export type CallsHistoryState = {
  loading: boolean;
  error: boolean;
  calls: Call[];
  call: CallDetail | null;
  filter: Filter;
};

export type Filter = {
  dateCreated: string;
  type: string;
  text: string;
  tags: string[];
  operatorIds: string[];
};

export enum CallType {
  CoBrowsing = 1,
  Phone = 2
}

export type Call = {
  id: string;
  dateCreated: string;
  dateEnd: string;
  type: CallType;
  operator: Operator;
  result: number;
  resultNote: string | null;
  phone: string;
  email: string;
  tags: string[];
};

export enum EntryType {
  Widget = 1,
  Code,
  BotX,
  CallScript
}

export type CallDetail = Call & {
  entryType: EntryType;
  evaluation: Evaluation;
  chat: Message[];
  client: Client;
};

export type Evaluation = {
  average: number;
  score: Score[];
};

export type Score = {
  id: string;
  question: string;
  answer: string | number;
};

export type Message = {
  id: string;
  dateCreated: string;
  sender: Sender;
  message: string;
};

export type Sender = 'system' | 'operator' | 'client';

export type Client = {
  fullName: string;
  ipAddress: string;
  browser: string;
  os: string;
  geoLocation: GeoLocation;
};

type GeoLocation = {
  country: string;
  region: string;
  city: string;
};
