import { createSlice } from '@reduxjs/toolkit';
import { User, UsersState } from '../../@types/account';
import axios from '../../utils/axios';
import { store } from '../store';

const initialState: UsersState = {
  loading: true,
  error: false,
  users: [],
  user: null
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.error = false;
    },
    getUsersSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.users = action.payload;
    },
    getUserSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.user = action.payload;
    },
    error(state) {
      state.loading = false;
      state.error = true;
    }
  }
});

// Reducer
export default slice.reducer;

export function getUsers() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: User[];
      } = await axios.get('/api/users');

      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}

export function getChat(callId: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: User;
      } = await axios.get('/api/user', { params: { callId } }); // should be ideally /api/users/<id>

      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}
