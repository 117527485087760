export const formatters: { [key: string]: { [key: string]: string } } = {
  percent: {
    cs: '0.0%',
    en: '0.0%'
  },
  percent_adjective: {
    cs: '0.0 %',
    en: '0.0%'
  }
};
