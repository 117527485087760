import faker from 'faker';
import { isEmpty, sampleSize } from 'lodash';
import { CallOrder, CallOrderDetail, Filter, Priority } from '../@types/call-orders';
import fakeRequest from '../utils/fakeRequest';
import mock from './mock';
import { operators } from './operators';

const callOrders: CallOrder[] = Array.from({ length: 30 }, () => {
  const random = Math.random();

  return {
    id: faker.datatype.uuid(),
    dateCreated: (Math.random() > 0.5 ? faker.date.recent() : faker.date.past()).toString(),
    priority:
      // eslint-disable-next-line no-nested-ternary
      Math.random() > 0.666
        ? Priority.High
        : Math.random() < 0.333
        ? Priority.Low
        : Priority.Normal,
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber('+420 ### ### ###'),
    note: faker.lorem.sentences(),
    status: random > 0.5 ? 'assigned' : 'new',
    tags: sampleSize(
      ['notebooky', 'dell', 'pocitace', 'it', 'mda'],
      faker.datatype.number({ min: 1, max: 5 })
    ),
    assignedOperator:
      Math.random() > 0.5
        ? {
            id: faker.datatype.uuid(),
            fullName: faker.name.findName(),
            photoUrl: ''
          }
        : undefined
  };
});

mock.onGet('/api/call-orders').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { priority, status, dateFrom, dateTo, text, tags } = config.params as Filter;
    let result = callOrders;

    if (priority) {
      result = result.filter((callOrder) => Priority[callOrder.priority] === priority);
    }
    if (status) {
      result = result.filter((callOrder) => callOrder.status === status);
    }
    if (dateFrom) {
      result = result.filter((callOrder) => new Date(callOrder.dateCreated) > new Date(dateFrom));
    }
    if (dateTo) {
      result = result.filter((callOrder) => new Date(callOrder.dateCreated) < new Date(dateTo));
    }
    if (tags.length) {
      result = result.filter((callOrder) => callOrder.tags.some((tag) => tags.indexOf(tag) !== -1));
    }
    if (!isEmpty(text)) {
      result = result.filter(
        (callOrder) =>
          callOrder.note.includes(text) ||
          callOrder.email.includes(text) ||
          callOrder.phone.includes(text)
      );
    }

    return [200, result.sort(_sortByPriority)]; // sort should be done server side
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet('/api/call-order').reply(async (config) => {
  try {
    await fakeRequest(1000);

    // const { callOrderId } = config.params;

    const testOperator = operators[Math.floor(Math.random() * operators.length)];

    return [
      200,
      {
        ...callOrders[0],
        assignedOperator: {
          ...(callOrders[0].assignedOperator && {
            ...callOrders[0].assignedOperator,
            id: '8864c717-587d-472a-929a-8e5f298024da-0'
          })
        },
        browsingHistory: [
          {
            id: faker.datatype.uuid(),
            dateVisited: new Date(2021, 4, 12, 10).toString(),
            url: 'https://www.travelportal.cz',
            title: 'TravelPortal.cz | Dovolená do celého světa na jedné adrese'
          },
          {
            id: faker.datatype.uuid(),
            dateVisited: new Date(2021, 4, 12, 10, 2).toString(),
            url: 'https://www.travelportal.cz/zajezdy?doprava=letecky&places=72075',
            title: 'Dovolená a zájezdy Tunisko - letecky | TravelPortal.cz'
          },
          {
            id: faker.datatype.uuid(),
            dateVisited: new Date(2021, 4, 12, 11).toString(),
            url:
              'https://www.travelportal.cz/zajezdy/tunisko/djerba/h8014_hotel-magic-iliade?doprava=letecky&places=72075',
            title: 'Hotel Iliade & Aquapark - Tunisko | TravelPortal.cz'
          }
        ],
        workflow: [
          {
            id: faker.datatype.uuid(),
            createdBy: null,
            dateCreated: new Date(2021, 4, 12, 11).toString(),
            previousState: null,
            currentState: 'callOrder.workflow.table.row.state.created'
          },
          {
            id: faker.datatype.uuid(),
            createdBy: testOperator,
            dateCreated: new Date(2021, 4, 13, 8, 37).toString(),
            previousState: 'callOrder.workflow.table.row.state.created',
            currentState: 'callOrder.workflow.table.row.state.assigned'
          },
          {
            id: faker.datatype.uuid(),
            createdBy: testOperator,
            dateCreated: new Date(2021, 4, 13, 9, 21).toString(),
            previousState: null,
            currentState: 'callOrder.workflow.table.row.state.call'
          }
        ],
        callsHistory: [
          {
            id: faker.datatype.uuid(),
            dateCreated: new Date(2021, 4, 13, 9, 21).toString(),
            dateEnd: new Date(2021, 4, 13, 9, 35).toString(),
            operator: testOperator,
            result: 2,
            resultNote: `Vše je moc drahé. ${faker.lorem.sentences()}`
          }
        ]
      } as CallOrderDetail
    ]; // callOrders.find((callOrder) => callOrder.id === callOrderId)
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

function _sortByPriority(left: CallOrder, right: CallOrder) {
  if (left.priority === right.priority) {
    if (left.dateCreated < right.dateCreated) {
      return -1;
    }

    if (left.dateCreated > right.dateCreated) {
      return 1;
    }
  }

  if (
    (left.priority === Priority.High && right.priority === Priority.Normal) ||
    (left.priority === Priority.High && right.priority === Priority.Low)
  ) {
    return -2;
  }
  if (left.priority === Priority.Normal && right.priority === Priority.Low) {
    return -1;
  }

  return 0;
}
