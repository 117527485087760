import { isEmpty } from 'lodash';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { Project, Role, Status } from '../@types/account';
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { apiConfig } from '../config';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import axiosMockAdapter from '../_apis_/mock';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  ChangeProject = 'CHANGE_PROJECT',
  ChangeRole = 'CHANGE_ROLE',
  ChangeStatus = 'CHANGE_STATUS'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.ChangeProject]: {
    project: Project;
  };
  [Types.ChangeRole]: {
    role: Role;
  };
  [Types.ChangeStatus]: {
    status: Status;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };

    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    case 'CHANGE_PROJECT': {
      state.user!.project = action.payload.project;

      return { ...state };
    }

    case 'CHANGE_ROLE': {
      state.user!.role = action.payload.role;

      return { ...state };
    }

    case 'CHANGE_STATUS': {
      state.user!.status = action.payload.status;

      return { ...state };
    }

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        // see TODO.md
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const mockAdapter = axios.defaults.adapter;
          if (!isEmpty(apiConfig.baseUrl)) {
            axiosMockAdapter.restore();
          }

          const response = await axios.get(apiConfig.users.v1.profile);

          if (!isEmpty(apiConfig.baseUrl)) {
            axios.defaults.adapter = mockAdapter;
          }

          if (response.data.status !== 'success') {
            // TODO: error?
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }

          const { user } = response.data.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const mockAdapter = axios.defaults.adapter;
    if (!isEmpty(apiConfig.baseUrl)) {
      axiosMockAdapter.restore();
    }

    const response = await axios.post(apiConfig.users.v1.login, {
      email,
      password
    });

    if (!isEmpty(apiConfig.baseUrl)) {
      axios.defaults.adapter = mockAdapter;
    }

    if (response.data.status !== 'success') {
      // TODO: error?
      dispatch({
        type: Types.Login,
        payload: {
          user: null
        }
      });
    }

    const { token, user } = response.data.data;

    setSession(token); // TODO do not store token to localStorage, see TODO.md
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken); // see TODO.md
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  const changeProject = ({ project }: { project: Project }) => {
    dispatch({
      type: Types.ChangeProject,
      payload: {
        project
      }
    });
  };

  const changeRole = ({ role }: { role: Role }) => {
    dispatch({
      type: Types.ChangeRole,
      payload: {
        role
      }
    });
  };

  const changeStatus = ({ status }: { status: Status }) => {
    dispatch({
      type: Types.ChangeStatus,
      payload: {
        status
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        changeProject,
        changeRole,
        changeStatus
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
