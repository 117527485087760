import { Alert, AlertTitle, Container } from '@material-ui/core';
import { ReactNode } from 'react';
import useAuth from '../hooks/useAuth';

type RoleBasedGuardProp = {
  accessibleRoles: String[];
  children: ReactNode | string;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { user } = useAuth();

  if (!accessibleRoles.includes(user!.role.id)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
