import jwt from 'jsonwebtoken';
import { User } from '../@types/account';
import { apiConfig } from '../config';
import fakeRequest from '../utils/fakeRequest';
import mock from './mock';

const JWT_SECRET = 'minimal-secret-key';
const JWT_EXPIRES_IN = '5 days';

export const users: User[] = [
  {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'Admin LiveSale',
    email: 'admin@livesale.cz',
    password: 'demo1234',
    photoUrl: '/static/mock-images/avatars/avatar_default.jpg',
    phoneNumber: '+40 777666555',
    role: {
      id: '',
      name: '',
      isDefault: true
    },
    roles: [
      {
        id: 'admin',
        name: 'account.role.admin',
        isDefault: true
      },
      {
        id: 'manager',
        name: 'account.role.manager',
        isDefault: false
      },
      {
        id: 'operator',
        name: 'account.role.operator',
        isDefault: false
      }
    ],
    project: {
      id: '9ee1ec49-562c-4f7d-9e83-66ac0ad6cbdd',
      name: 'LiveSale Orange'
    },
    projects: [
      {
        id: '9ee1ec49-562c-4f7d-9e83-66ac0ad6cbdd',
        name: 'LiveSale Orange'
      },
      {
        id: 'ab61d3b5-fa67-4163-99db-3170692ee45a',
        name: 'LiveSale TravelPortal'
      },
      {
        id: 'f2b0be9f-13f1-4c87-a7bf-9d40882a53a4',
        name: 'LiveSale MP'
      }
    ],
    status: {
      id: 'offline',
      name: 'Offline'
    },
    tags: ['konzole', 'pocitace', 'dell'],
    isActive: true
  },
  {
    id: '8864c717-587d-472a-929a-8e5f298024db-0',
    displayName: 'Operátor LiveSale',
    email: 'op@livesale.cz',
    password: 'demo1234',
    photoUrl: '/static/mock-images/avatars/avatar_default.jpg',
    phoneNumber: '+420 777 666 555',
    role: {
      id: '',
      name: '',
      isDefault: true
    },
    roles: [
      {
        id: 'operator',
        name: 'account.role.operator',
        isDefault: true
      }
    ],
    project: {
      id: '9ee1ec49-562c-4f7d-9e83-66ac0ad6cbdd',
      name: 'LiveSale Orange'
    },
    projects: [
      {
        id: '9ee1ec49-562c-4f7d-9e83-66ac0ad6cbdd',
        name: 'LiveSale Orange'
      },
      {
        id: 'd746688c-93e9-42d8-8144-7f4d27eb290c',
        name: 'LiveSale HP'
      }
    ],
    status: {
      id: 'online',
      name: 'Online'
    },
    tags: ['dell', 'it', 'telefony', 'konzole'],
    isActive: false
  }
];

mock.onPost(apiConfig.users.v1.login).reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { email, password } = JSON.parse(config.data);
    const user = users.find((_user) => _user.email === email);

    if (!user || user.password !== password) {
      return [404, { code: 'login.errors.incorrectData' }];
    }

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    user.role = user.roles.find((role) => role.isDefault)!;

    return [200, { accessToken, user }];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

mock.onGet(apiConfig.users.v1.profile).reply((config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Authorization token missing' }];
    }

    const accessToken = Authorization.split(' ')[1];
    const data: any = jwt.verify(accessToken, JWT_SECRET);
    const userId = typeof data === 'object' ? data?.userId : '';
    const user = users.find((_user) => _user.id === userId);

    if (!user) {
      return [401, { message: 'Invalid authorization token' }];
    }

    user.role = user.roles.find((role) => role.isDefault)!;

    return [200, { user }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/users').reply(async () => {
  try {
    await fakeRequest(1000);

    return [200, users];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
