import numeral from 'numeral';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFnsLocales } from '../locales/i18n';

// https://iconscout.com/icon/united-kingdom-flag-country-nation-union-empire
const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/flags/uk.svg'
  },
  {
    value: 'cs',
    label: 'Czech',
    icon: '/static/icons/flags/cs.svg'
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  useEffect(() => {
    if (i18n) {
      i18n.on('languageChanged', (lng) => numeral.locale(lng));
      numeral.locale(i18n.language);
    }
  }, [i18n, currentLang]);

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
    availableLocales: dateFnsLocales
  };
}
