import { Box, ListItemText, MenuItem, Typography } from '@material-ui/core';
import * as React from 'react';
import { Project } from '../../@types/account';
import { MButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';

export const ProjectPopover = () => {
  const { user, changeProject } = useAuth();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const onChangeProject = (newProject: Project) => {
    changeProject({ project: newProject });
  };

  if (user!.projects.length === 1) {
    return (
      <MButton sx={{ ml: { xs: 1.5, sm: 2, lg: 3 } }}>
        <Typography variant="button">{user!.project.name}</Typography>
      </MButton>
    );
  }

  return (
    <>
      <MButton ref={anchorRef} onClick={() => setOpen(true)} sx={{ ml: { xs: 1.5, sm: 2, lg: 3 } }}>
        <Typography variant="button">{user!.project.name}</Typography>
      </MButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {user!.projects.map((project) => (
            <MenuItem
              key={project.id}
              selected={project.id === user!.project.id}
              onClick={() => {
                onChangeProject(project);
                setOpen(false);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {project.name}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};
