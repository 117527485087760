import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
              <Dashboard />
            </RoleBasedGuard>
          )
        },
        {
          path: 'call-orders',
          children: [
            {
              path: '/',
              element: <CallOrders />
            },
            {
              path: ':callOrderId',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <CallOrder />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'calls-history',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <CallsHistory />
                </RoleBasedGuard>
              )
            },
            {
              path: ':callOrderId',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <CallHistory />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'chat',
          children: [
            {
              path: '/',
              element: <Navigate to="/chat/active" replace />
            },
            {
              path: 'active',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <Chat />
                </RoleBasedGuard>
              )
            },
            {
              path: 'active/:chatId',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <Chat />
                </RoleBasedGuard>
              )
            },
            {
              path: 'history',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <Chat />
                </RoleBasedGuard>
              )
            },
            {
              path: 'history/:chatId',
              element: (
                <RoleBasedGuard accessibleRoles={['operator', 'manager', 'admin', 'super-admin']}>
                  <Chat />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'widget',
          element: (
            <RoleBasedGuard accessibleRoles={['admin', 'super-admin']}>
              <Widget />
            </RoleBasedGuard>
          )
        },
        {
          path: 'employee-management',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard accessibleRoles={['manager', 'admin', 'super-admin']}>
                  <EmployeeManagement />
                </RoleBasedGuard>
              )
            },
            {
              path: ':employeeId',
              element: (
                <RoleBasedGuard accessibleRoles={['manager', 'admin', 'super-admin']}>
                  <EmployeeManagement />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'settings',
          element: (
            <RoleBasedGuard accessibleRoles={['admin', 'super-admin']}>
              <Settings />
            </RoleBasedGuard>
          )
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const CallOrders = Loadable(lazy(() => import('../pages/CallOrders')));
const CallOrder = Loadable(lazy(() => import('../pages/CallOrder')));
const CallsHistory = Loadable(lazy(() => import('../pages/CallsHistory')));
const CallHistory = Loadable(lazy(() => import('../pages/CallHistory')));
const Chat = Loadable(lazy(() => import('../pages/Chat')));
const EmployeeManagement = Loadable(lazy(() => import('../pages/EmployeeManagement')));
const Widget = Loadable(lazy(() => import('../pages/Widget')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
