import { addMinutes } from 'date-fns';
import faker from 'faker';
import { isEmpty, sample, sampleSize } from 'lodash';
import { Call, CallDetail, CallType, EntryType, Filter, Message } from '../@types/calls-history';
import fakeRequest from '../utils/fakeRequest';
import mock from './mock';
import { operators } from './operators';

const calls: Call[] = Array.from({ length: 30 }, () => {
  const random = Math.random();
  const dateCreated = faker.date.recent();
  const dateEnd = addMinutes(dateCreated, Math.random() * 30);
  const testOperator = operators[Math.floor(Math.random() * operators.length)];

  return {
    id: faker.datatype.uuid(),
    dateCreated: dateCreated.toString(),
    dateEnd: dateEnd.toString(),
    type: random > 0.5 ? CallType.CoBrowsing : CallType.Phone,
    operator: testOperator,
    result: 1,
    resultNote: faker.lorem.sentences(),
    phone: faker.phone.phoneNumber('+420 ### ### ###'),
    email: faker.internet.email(),
    tags: sampleSize(
      ['notebooky', 'dell', 'pocitace', 'it', 'mda'],
      faker.datatype.number({ min: 1, max: 5 })
    )
  };
}).sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());

mock.onGet('/api/calls-history').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { dateCreated, type, text, tags, operatorIds } = config.params as Filter;
    let result = calls;

    if (dateCreated) {
      result = result.filter((call) => new Date(call.dateCreated) < new Date(dateCreated));
    }
    if (type) {
      result = result.filter((call) => CallType[call.type] === type);
    }
    if (tags.length) {
      result = result.filter((call) => call.tags.some((tag) => tags.indexOf(tag) !== -1));
    }
    if (!isEmpty(text)) {
      result = result.filter(
        (call) =>
          call.resultNote?.includes(text) || call.email.includes(text) || call.phone.includes(text)
      );
    }
    if (operatorIds.length) {
      result = result.filter((call) => operatorIds.indexOf(call.operator.id) !== -1);
    }

    return [200, result];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});

const average = (array: number[]) => array.reduce((a, b) => a + b) / array.length;
const addSeconds = (date: Date, seconds: number) => new Date(date.getTime() + seconds * 1000);

mock.onGet('/api/call-history').reply(async (config) => {
  try {
    await fakeRequest(1000);

    // const { callId } = config.params;

    const firstAnswer = 5;
    const secondAnswer = Math.floor(Math.random() * 6);
    const firstChatMessage = addSeconds(new Date(calls[0].dateCreated), 30);
    const messages = [
      {
        id: faker.datatype.uuid(),
        dateCreated: firstChatMessage.toString(),
        sender: 'system',
        message: 'Dobrý den, vítejte v co-browsingu.'
      },
      ...Array.from(
        { length: 5 },
        () =>
          ({
            id: faker.datatype.uuid(),
            dateCreated: '',
            sender: Math.random() > 0.5 ? 'operator' : 'client',
            message: faker.lorem.sentence()
          } as Message)
      )
    ];
    messages.forEach((entry, index, a) => {
      if (index !== 0) {
        entry.dateCreated = addSeconds(
          new Date(a[index - 1].dateCreated),
          Math.random() * 120
        ).toString();
      }
    });

    return [
      200,
      {
        ...calls[0],
        entryType: sample(
          Object.keys(EntryType)
            .map((n) => Number.parseInt(n, 10))
            .filter((n) => !Number.isNaN(n))
        ) as EntryType,
        evaluation: {
          average: average([firstAnswer, secondAnswer]),
          score: [
            {
              id: faker.datatype.uuid(),
              question: 'Jak hodnotíte službu LiveSale?',
              answer: 'Cajk'
            },
            {
              id: faker.datatype.uuid(),
              question: 'Jak hodnotíte přístup operátora Martina?',
              answer: firstAnswer
            },
            {
              id: faker.datatype.uuid(),
              question: 'Je Martin nejlepší?',
              answer: 'Jasně!'
            },
            {
              id: faker.datatype.uuid(),
              question: 'Jaké je velku počasí?',
              answer: secondAnswer
            }
          ]
        },
        chat: messages,
        client: {
          fullName: faker.name.findName(),
          ipAddress: '10.10.1.1',
          browser: 'Chrome 90',
          os: 'Windows 10',
          geoLocation: {
            country: 'Czechia',
            region: 'Zlín',
            city: 'Horni Lidec'
          }
        }
      } as CallDetail
    ];
  } catch (error) {
    console.error(error);
    return [500, { code: 'Internal server error' }];
  }
});
