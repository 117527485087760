import { Box, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { useRef, useState } from 'react';
import { Role } from '../../@types/account';
import { MButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';

export const RolePopover = () => {
  const { user, changeRole } = useAuth();
  const { translate } = useLocales();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onChangeProject = (newRole: Role) => {
    changeRole({ role: newRole });
  };

  if (user!.roles.length === 1) {
    return (
      <MButton sx={{ ml: { xs: 1.5, sm: 2, lg: 3 } }}>
        <Typography variant="button">{translate(user!.role.name)}</Typography>
      </MButton>
    );
  }

  return (
    <>
      <MButton ref={anchorRef} onClick={() => setOpen(true)} sx={{ ml: { xs: 1.5, sm: 2, lg: 3 } }}>
        <Typography variant="button">{translate(user!.role.name)}</Typography>
      </MButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {user!.roles.map((availableRole) => (
            <MenuItem
              key={availableRole.id}
              selected={availableRole.id === user!.role.id}
              onClick={() => {
                onChangeProject(availableRole);
                setOpen(false);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {translate(availableRole.name)}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};
