import { Call } from './calls-history';
import { Operator } from './operators';

export type CallOrdersState = {
  loading: boolean;
  error: boolean;
  callOrders: CallOrder[];
  callOrder: CallOrderDetail | null;
  filter: Filter;
  isOpenModal: boolean;
};

export type CallOrder = {
  id: string;
  dateCreated: string;
  priority: Priority;
  phone: string;
  email: string;
  note: string;
  status: Status;
  tags: string[];
  assignedOperator?: Operator;
};

export type CallOrderDetail = CallOrder & {
  browsingHistory: BrowsingHistory[];
  workflow: Workflow[];
  callsHistory: Call[];
};

export enum Priority {
  Normal = 1,
  Low = 2,
  High = 3
}

export type Status = 'new' | 'my' | 'assigned' | 'closed';

export type Filter = {
  priority: string;
  status: Status;
  dateFrom: string;
  dateTo: string;
  text: string;
  tags: string[];
};

export type BrowsingHistory = {
  id: string;
  dateVisited: string;
  title: string;
  url: string;
};

export type Workflow = {
  id: string;
  createdBy: Operator | null;
  dateCreated: string;
  previousState: string | null;
  currentState: string;
};
