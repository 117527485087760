function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  page404: '/404'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  callOrders: {
    root: path(ROOTS_DASHBOARD, '/call-orders'),
    detail: path(ROOTS_DASHBOARD, '/call-orders/:callOrderId')
  },
  callsHistory: {
    root: path(ROOTS_DASHBOARD, '/calls-history'),
    detail: path(ROOTS_DASHBOARD, '/calls-history/:callOrderId')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    active: path(ROOTS_DASHBOARD, '/chat/active'),
    detail: path(ROOTS_DASHBOARD, '/chat/active/:chatId'),
    history: path(ROOTS_DASHBOARD, '/chat/history'),
    historyDetail: path(ROOTS_DASHBOARD, '/chat/history/:chatId')
  },
  widget: path(ROOTS_DASHBOARD, '/widget'),
  employeeManagement: {
    root: path(ROOTS_DASHBOARD, '/employee-management'),
    detail: [path(ROOTS_DASHBOARD, '/employee-management/:employeeId')]
  },
  settings: path(ROOTS_DASHBOARD, '/settings'),
  account: path(ROOTS_DASHBOARD, '/account')
};
