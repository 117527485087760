import { createSlice } from '@reduxjs/toolkit';
import { Call, CallsHistoryState, CallType, Filter } from '../../@types/calls-history';
import axios from '../../utils/axios';
import { store } from '../store';

const initialState: CallsHistoryState = {
  loading: true,
  error: false,
  filter: {
    dateCreated: new Date().toString(),
    type: CallType[CallType.CoBrowsing],
    text: '',
    tags: [],
    operatorIds: []
  },
  calls: [],
  call: null
};

const slice = createSlice({
  name: 'callsHistory',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    getSuccess(state, action) {
      state.loading = false;
      state.calls = action.payload;
    },
    getCallSuccess(state, action) {
      state.loading = false;
      state.call = action.payload;
    },
    error(state) {
      state.loading = false;
      state.error = true;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    unsetCalls(state) {
      state.calls = [];
    },
    unsetCall(state) {
      state.call = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFilter, unsetCalls, unsetCall } = slice.actions;

export function getCalls(filter: Filter) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: Call[];
      } = await axios.get('/api/calls-history', { params: filter });

      dispatch(slice.actions.getSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}

export function getCall(callId: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: Call;
      } = await axios.get('/api/call-history', { params: { callId } }); // should be ideally /api/calls-history/<id>

      dispatch(slice.actions.getCallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}
