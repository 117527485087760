import { createSlice } from '@reduxjs/toolkit';
import { Chat, ChatDetail, ChatHistoryState, Filter } from '../../@types/chat-history';
import axios from '../../utils/axios';
import { store } from '../store';

const initialState: ChatHistoryState = {
  loading: true,
  error: false,
  filter: {
    dateCreated: new Date().toString(),
    text: '',
    tags: [],
    operatorIds: []
  },
  chats: [],
  chat: null
};

const slice = createSlice({
  name: 'chatHistory',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.error = false;
    },
    getChatsSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.chats = action.payload;
    },
    getChatSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.chat = action.payload;
    },
    error(state) {
      state.loading = false;
      state.error = true;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    unsetChats(state) {
      state.chats = [];
    },
    unsetChat(state) {
      state.chat = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFilter, unsetChats, unsetChat } = slice.actions;

export function getChats(filter: Filter) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: Chat[];
      } = await axios.get('/api/chats/history', { params: filter }); // should be ideally /api/chat/history

      dispatch(slice.actions.getChatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}

export function getChat(callId: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: ChatDetail;
      } = await axios.get('/api/chat/history', { params: { callId } }); // should be ideally /api/chat/history/<id>

      dispatch(slice.actions.getChatSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.error());
    }
  };
}
