import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountsReducer from './slices/accounts';
import callOrdersReducer from './slices/call-orders';
import callsHistoryReducer from './slices/calls-history';
import chatReducer from './slices/chat';
import chatHistoryReducer from './slices/chat-history';
import dashboardReducer from './slices/dashboard';

const getPersistConfig = (key: string) => ({
  key,
  storage,
  keyPrefix: 'redux-',
  whitelist: ['filter']
});

const rootReducer = combineReducers({
  accounts: accountsReducer,
  dashboard: dashboardReducer,
  chat: chatReducer,
  callOrders: persistReducer(getPersistConfig('callOrders'), callOrdersReducer),
  callsHistory: callsHistoryReducer,
  chatHistory: chatHistoryReducer
});

export { rootReducer };
