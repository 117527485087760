/* eslint-disable import/no-duplicates */
import { format as formatDate, formatDistanceStrict, formatDistanceToNow } from 'date-fns';
import { cs, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDate } from 'lodash';
import numeral from 'numeral';
import { initReactI18next } from 'react-i18next';
import csLocales from './cs.json';
import enLocales from './en.json';
import { formatters } from './formatters';

// to pick correct number of plural keys use https://jsfiddle.net/sm9wgLze

export const dateFnsLocales = { en: enUS, cs };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      cs: { translations: csLocales }
    },
    lng: localStorage.getItem('i18nextLng') || 'cs',
    fallbackLng: 'cs',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format && Object.keys(formatters).includes(format)) {
          return numeral(value).format(formatters[format][lng!]);
        }

        if (format && isDate(new Date(value))) {
          if (format === 'ago') {
            return formatDistanceToNow(new Date(value), {
              addSuffix: true,
              locale: dateFnsLocales[lng! as 'cs' | 'en']
            });
          }
          if (format === 'timeDistance') {
            return formatDistanceStrict(new Date(), new Date(value), {
              locale: dateFnsLocales[lng! as 'cs' | 'en']
            });
          }

          return formatDate(new Date(value), format, {
            locale: dateFnsLocales[lng! as 'cs' | 'en']
          });
        }

        return value;
      }
    }
  });

// en registered by default
numeral.register('locale', 'cs', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 't',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  ordinal: (num: number) => num.toString(),
  currency: {
    symbol: 'Kč'
  }
});

export default i18n;
