import { Box, BoxProps } from '@material-ui/core';

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 130 110">
        <path
          d="M 123.31 63.55 A 59.42 59.42 0 1 0 20.24 103.86 a 0.72 0.72 0 0 0 0.57 0.28 a 0.74 0.74 0 0 0 0.52 -0.21 h 0 a 60.16 60.16 0 0 1 26.13 -15.3 A 3.14 3.14 0 0 0 50 85.54 a 3.08 3.08 0 0 0 -1.4 -2.6 a 24.73 24.73 0 1 1 30.7 0 a 3.13 3.13 0 0 0 1.09 5.7 a 60.31 60.31 0 0 1 26.07 15.27 a 0.73 0.73 0 0 0 0.55 0.25 a 0.76 0.76 0 0 0 0.54 -0.23 l 0 0 A 59.21 59.21 0 0 0 123.31 63.55 Z"
          fill="#d01419"
        />
      </svg>
    </Box>
  );
}
