import { isEmpty } from 'lodash';

export function capString(string: string, chars: number = 20) {
  if (isEmpty(string) || string.length <= chars) {
    return string;
  }

  return `${string.substring(0, chars)}…`;
}

export function middleEllipsis(string: string, maxChars: number = 35) {
  if (string.length > maxChars) {
    return `${string.substr(0, (maxChars / 3) * 2)}…${string.substr(
      string.length - 20,
      string.length
    )}`;
  }
  return string;
}
